import React from "react"
import Layout from "../components/layout"

export default function Terms() {
    return (
        <Layout>
            <div className="wrapper">
                <h1>Terms of Service</h1>
                <p>Last updated: April 2018</p>
                <p>Thank you for using our products and services (the “Service/s”). By becoming a member or using our Services you accept and agree to these Terms and Conditions (“Terms”). Therefore, you must read these Terms to make sure that they contain all that you want and nothing that you are not happy with. If you are not happy with these Terms you should not register with Vegly.</p>
                <h2 id="About-Vegly"><a href="#About-Vegly" class="headerlink" title="About Vegly"></a>About Vegly</h2><p>Vegly is a service developed and provided by Polygram Digital Ltd. (“Company”) a company registered in England and Wales under number 10826540 whose registered office is at Chancery Station House, 31-33 High Holborn, London, WC1V 6AX. The Service is available online at <a href="https://www.eatvegly.com">https://www.eatvegly.com</a> (“Website”) and the purpose is to provide weekly meal plans for vegetarians.</p>
                <h2 id="Using-our-Services"><a href="#Using-our-Services" class="headerlink" title="Using our Services"></a>Using our Services</h2><p>To use our Services you must follow some simple rules:</p>
                <ol>
                    <li><p>All Content included on the Website is the property of the Company, our affiliates or other relevant third parties. In the these Terms and conditions, Content means any text, graphics, images, audio, video, software, data, compilations, page layout, underlying code and software and any other form of information capable of being stored in a computer that appears on or forms part of this Website. By continuing to use the Website and the Services you acknowledge that such Content is protected by copyright, trademarks, database rights and other intellectual property rights. You agree not to use Content for any other purposes except for your use of the Services, unless required otherwise by applicable mandatory law.</p>
                    </li>
                    <li><p>You may not use the Services for any of the following purposes:</p>
                        <ul>
                            <li>in any way which causes, or may cause, damage to the Company or interferes with any other person’s use of the Services;</li>
                            <li>in any way which is harmful, unlawful, illegal, abusive, harrassing, threatening or otherwise objectionable or in breach of law and these Terms;</li>
                            <li>making, transmitting or storing electronic copies of Content protected by copyright without written permission of the Company.</li>
                        </ul>
                    </li>
                    <li><p>The Service is not intended for use by persons under the age of 16. Therefore, to use the Service you agree to be at least 16 years old.</p>
                    </li>
                    <li><p>Violation of any of these Terms will lead to a direct termination of your user account and subscription. If you violate these Terms, you will not get a refund.</p>
                    </li>
                </ol>
                <h2 id="Your-account"><a href="#Your-account" class="headerlink" title="Your account"></a>Your account</h2><p>To use our Services you must create an account and you must ensure that the details provided by you on registration or at any time are correct and complete. You are solely responsible for all activities on your account and your membership, including your email and password, with the Website is personal and may not be transferred or used by someone else. You are responsible for storing your login details in a safe manner. The Company is not in any way responsible for any loss or damage caused by unauthorized access to your account or use of your login details. If you learn of or suspect any authorized use of your account, you must immediately inform the Company using the contact detials at the bottom of this document.</p>
                <h2 id="Cancel-your-account"><a href="#Cancel-your-account" class="headerlink" title="Cancel your account"></a>Cancel your account</h2><p>You may cancel your membership with the Website at any time by informing us in writing to the email address at the bottom of this document. If you do so, you must immediately stop using the Website.</p>
                <h2 id="Links-to-other-websites"><a href="#Links-to-other-websites" class="headerlink" title="Links to other websites"></a>Links to other websites</h2><p>The Website may contain links to other sites. Unless expressly stated, these sites are not under the control of the Company or that of our affiliates. We assume no responsibility for the content of such sites and disclaim liability for any and all forms of loss or damage arising out of the use of them. The inclusion of a link to another site on this Website does not imply any endorsement of the sites themselves or of those in control of them.</p>
                <h2 id="Disclaimer-and-limitation-of-liability"><a href="#Disclaimer-and-limitation-of-liability" class="headerlink" title="Disclaimer and limitation of liability"></a>Disclaimer and limitation of liability</h2><p>The materials on the Website are provided on an “AS IS” basis. The Company makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.<br />Further, the Company does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its Website or otherwise relating to such materials or on any sites linked to this Website and Services.</p>
                <p>Nothing in these Terms and conditions will: (a) limit or exclude our or your liability for death or personal injury resulting from our or your negligence, as applicable; (b) limit or exclude our or your liability for fraud or fraudulent misrepresentation; or (c) limit or exclude any of your liabilities in any way that is not permitted under applicable law.</p>
                <p>To the extent that the Website and Content are provided free of charge, the Company will not be liable to you for any loss or damage of any kind. The Company will not be liable to you in respect of any losses arising out of events beyond its reasonable control. To the maximum extent permitted by law, the Company accepts no liability for any of the following: (a) loss or corruption of any data, database or software; (b) any special, indirect or consequential loss or damage.</p>
                <h2 id="General"><a href="#General" class="headerlink" title="General"></a>General</h2><p>You are not entitled to transfer any of your rights under these Terms to any other person. The Company may transfer its rights under these Terms where it reasonably believes your rights will not be affected.</p>
                <p>The Company may revise these Terms from time to time. Such revised Terms will apply to the Website and Service from the date of publication. You should check the Terms regurarly to ensure familiarity with the then current version.</p>
                <h2 id="Governing-law-and-dispute-resolution"><a href="#Governing-law-and-dispute-resolution" class="headerlink" title="Governing law and dispute resolution"></a>Governing law and dispute resolution</h2><p>These Terms and the use of the Services are governed by the laws of England and Wales and all disputes or claims arising out of or relating to these Terms shall be subject to the exclusive jurisdiction of the English and Welsh courts.</p>
                <h2 id="Contact-details"><a href="#Contact-details" class="headerlink" title="Contact details"></a>Contact details</h2><p>For further information, enquiries or assistance you may contact Polygram Digital Ltd.</p>
                <h3 id="Company-information"><a href="#Company-information" class="headerlink" title="Company information"></a>Company information</h3><p>Polygram Digital Ltd.<br />Chancery Station House,<br />31-33 High Holborn,<br />London,<br />WC1V 6AX</p>
                <h3 id="Contact-E-mail"><a href="#Contact-E-mail" class="headerlink" title="Contact E-mail"></a>Contact E-mail</h3><p>hello@eatvegly.com</p>
            </div>
        </Layout>
    )
}